import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import {
  getControl,
  getRootValue,
  getShowGroupActionButton,
} from "./form.selector";
import { plans } from "@basicare/common/src/Constants/planTypes";

export const isGeneralInformationPageValid = (
  state: RxMembershipSignUpState
): boolean => {
  const generalInformation = getControl(state, "generalInformation");

  return generalInformation.valid;
};

export const getPlanType = (state: RxMembershipSignUpState) => {
  const {
    generalInformation: { planType },
  } = getRootValue(state);

  return planType;
};

export const getPlanPrice = (state: RxMembershipSignUpState) => {
  const planType = getPlanType(state);

  return plans.find((plan) => plan.planType === planType)?.price;
};

export const getTrialType = (state: RxMembershipSignUpState) => {
  return state.applyPromoCode?.data?.type;
};

export const getShowEmailNotMatchError = (state: RxMembershipSignUpState) =>
  getControl(state, "generalInformation").errors.emailConfirmed &&
  getControl(state, "generalInformation.confirmEmail").touched;

export const getShowGeneralInformationActionBtn = (
  state: RxMembershipSignUpState
) =>
  getShowGroupActionButton({
    state,
    groupName: "generalInformation",
    submitTouched: state.generalInformationSubmitTouched,
  }) || getShowEmailNotMatchError(state);
