import FooterService from "Services/FooterService";
import API from "Helpers/API";
import { useReactable } from "@reactables/react";
import { RxGetFooterContent } from "Features/Shared/Rx/RxFooter";
import { Icon, Link } from "Features/Shared/Models/footer.model";

const IconContainer = ({
  icon,
  className = "",
}: {
  icon: Icon;
  className?: string;
}) => (
  <img
    src={icon?.url}
    width={icon?.width}
    height={icon?.height}
    alt={icon?.alt}
    className={className}
  />
);

const IconLinkContainer = ({
  link,
  icon,
  className = "",
}: {
  link: Link;
  icon: Icon;
  className?: string;
}) => (
  <a
    href={link.url}
    target={link.target}
    rel="noreferrer"
    className={className}
  >
    <IconContainer icon={icon} />
  </a>
);

const Footer = ({ referralParam = "" }: { referralParam?: string }) => {
  const [footerState] = useReactable(
    RxGetFooterContent,
    new FooterService(new API(process.env.REACT_APP_WP_API_LOCATION))
  );

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="footer__basics">
              <div
                className={`footer__basics-title ${
                  footerState?.loading ? "skeleton dark skeleton-image" : ""
                }`}
              >
                <IconContainer
                  icon={footerState?.data?.logo_image}
                  className="footer__basics-logo"
                />
              </div>
              <div className="footer__basics-text mb-3 mb-md-4">
                <p
                  className={`mb-3  ${
                    footerState?.loading
                      ? "skeleton dark skeleton-text skeleton-text__tall"
                      : ""
                  } `}
                >
                  {footerState?.data?.text_area_left_1}
                </p>
                <p
                  className={
                    footerState?.loading
                      ? "skeleton dark skeleton-text skeleton-text__tall"
                      : ""
                  }
                >
                  {footerState?.data?.text_area_left_2}
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div
              className={
                footerState?.loading
                  ? "skeleton dark skeleton-text skeleton-text__xltall"
                  : ""
              }
            >
              <div className="mt-3 mt-xl-0">
                <p className="mb-4">{footerState?.data?.text_area_right}</p>
                <h6>{footerState?.data?.contact.header}</h6>
                <p className="mb-2">
                  {footerState?.data?.contact.company_name}
                  {!footerState?.loading ? ", " : ""}
                  {footerState?.data?.contact.full_address}
                </p>
                <p className="mb-4">
                  <a
                    className="mr-3 footer__link"
                    href={`tel:${footerState?.data?.contact.phone_number}`}
                  >
                    {footerState?.data?.contact.phone_number}
                  </a>{" "}
                  <a
                    className="footer__link"
                    href={`mailto:${footerState?.data?.contact.email}`}
                  >
                    {footerState?.data?.contact.email}
                  </a>
                </p>
                <div className="row">
                  <div className="col-12 col-xl-auto mb-4">
                    {footerState?.data?.social_media.map((item, index) => (
                      <div key={item.icon.id} className="d-inline-block">
                        <IconLinkContainer
                          link={item.link}
                          icon={item.icon}
                          className={index > 0 ? "ml-4" : ""}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="col-12 col-xl-auto footer__hipaa d-flex align-items-center flex-grow-1 mb-4">
                    {footerState?.data?.compliance.length && (
                      <IconLinkContainer
                        link={footerState?.data?.compliance[0].link}
                        icon={footerState?.data?.compliance[0].icon}
                      />
                    )}
                    <a
                      href={`${
                        footerState?.data?.privacy_policy.url + referralParam
                      }`}
                      className="ml-5 footer__link"
                    >
                      {footerState?.data?.privacy_policy.title}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`footer__copyright ${
            footerState?.loading ? "skeleton dark skeleton-text" : ""
          } `}
        >
          {footerState?.data?.text_area_bottom}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
