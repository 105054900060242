const PageContentLayout = ({ children }) => (
  <div className="container container--fullwidth-mobile">
    <div className="page-wrapper">
      <div className="page-content mt-lg-4">
        <div className="d-flex flex-column align-items-center justify-content-center align-items-center py-4">
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default PageContentLayout;
