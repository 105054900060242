import { useEffect } from "react";
import { scrollToError } from "@jauntin/utilities";
import { Link } from "react-router-dom";
import Button from "@basicare/common/src/Components/Themed/Button";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { Field } from "@reactables/react-forms";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import BrainTreeCCFields from "Features/Shared/Components/BrainTreeCCFields";
import DifferentAddressFields from "Features/Shared/Components/DifferentAddressFields";
import { ReactComponent as ChevronLeft } from "@basicare/common/src/Assets/Images/chevron-left-solid.svg";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Summary from "../Summary/Summary";
import { useHostedFields } from "Features/Shared/Hooks/useHostedFields";

import {
  getControl,
  getValue,
  getShowGroupActionButton,
} from "../../Rx/RxMembershipSignUp/Selectors/form.selector";
import { getFieldValidStates } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/payment.selector";
import {
  getPlanType,
  getTrialType,
} from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/generalInformation.selector";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import { ExtendedMeta } from "@jauntin/utilities";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";

const Payment = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state, actions] = rxMembershipSignUp;

  const { paymentSubmitTouched } = state;

  const showActionButton = getShowGroupActionButton({
    state,
    submitTouched: paymentSubmitTouched,
  });

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || paymentSubmitTouched) && error);

  const hasPaymentControl = state.applicationForm["payment"] !== undefined;

  // Reset billing info on unmount to keep valid states in sync with braintree hosted fields
  useEffect(() => actions.applicationForm.resetCCFields, [actions]);

  const { tokenizer, bindHostedFields } = useHostedFields();

  const { applicationForm } = state;

  const fieldValidStates = getFieldValidStates(state);
  const planType = getPlanType(state);
  const trialType = getTrialType(state);
  const termsLink = `${process.env.REACT_APP_WP_API_LOCATION}terms-conditions/`;

  return (
    <div className="container container--fullwidth-mobile">
      <div className="page-heading">
        <h1>STEP 3: ENTER YOUR PAYMENT INFORMATION</h1>
      </div>
      <div className="page-wrapper">
        <div className="page-content">
          <Breadcrumbs />
          <div className="m-4"></div>
          <Summary rxMembershipSignUp={rxMembershipSignUp} fullScreen />
          {hasPaymentControl && (
            <>
              <section className="page-content__section">
                <h2 className="mb-1">Payment information</h2>
                {trialType === PromoCodeTypes.TrialPurchase ? (
                  <div className="mb-3">
                    Please provide your payment information. You won't be
                    charged until your trial period ends
                  </div>
                ) : (
                  <div className="mb-3">
                    You will be charged on{" "}
                    {planType === PlanTypes.Annual ? "an" : "a"} {planType}{" "}
                    basis
                  </div>
                )}
                <BrainTreeCCFields
                  onHostedFieldsLoaded={bindHostedFields}
                  fieldValidStates={fieldValidStates}
                  onValidityChange={actions.applicationForm.validateCCFields}
                  onBlur={actions.applicationForm.markAsCCFieldTouched}
                  enableShowErrors={paymentSubmitTouched}
                />
              </section>
              <Field
                name="payment.billing.sameAddressAsInsured"
                component={StyledCheckboxField}
                onChange={(e) => {
                  actions.applicationForm.selectSameAddressAsInsured(
                    e.currentTarget.checked
                  );
                }}
                label="Billing address is the same as the primary member"
              />
              {getControl(state, "payment.billing.differentAddressFields") && (
                <DifferentAddressFields
                  groupName="payment.billing.differentAddressFields"
                  selectBillingAddressPlace={
                    actions.applicationForm.selectBillingAddressPlace
                  }
                  clearBillingAddress={
                    actions.applicationForm.clearBillingAddress
                  }
                  addressValue={
                    getValue(
                      state,
                      "payment.billing.differentAddressFields.address.address"
                    ) as string
                  }
                  showErrors={showErrors}
                />
              )}

              <Field
                name="payment.authorizationConsent"
                component={StyledCheckboxField}
                label={
                  <>
                    By confirming your subscription, you allow BasiCare Plus LLC
                    to charge you now and for future payments in accordance with
                    their{" "}
                    <a href={termsLink} target="_blank" rel="noreferrer">
                      terms
                    </a>
                    .
                  </>
                }
                showErrors={showErrors}
              />
            </>
          )}
          <div className="page-content__section row">
            <Link
              to="/dependents"
              className="col-6 col-lg-auto pl-0 text-decoration-none"
            >
              <Button
                outline
                className="w-100 d-flex justify-content-center align-items-center"
              >
                <ChevronLeft className="button__chevrone-right mr-1" /> Back
              </Button>
            </Link>
            <div className="col-6 col-lg-auto pr-0">
              {showActionButton ? (
                <AsyncButton
                  className="w-100"
                  onClick={() => {
                    actions.touchPaymentSubmit();
                    actions.purchase({
                      state,
                      tokenizer: hasPaymentControl ? tokenizer : null,
                    });
                  }}
                  spinning={state.purchase.loading}
                  disabled={!applicationForm.root.valid}
                >
                  Purchase
                </AsyncButton>
              ) : (
                <Button
                  className="w-100"
                  onClick={() => {
                    actions.touchPaymentSubmit();
                    scrollToError();
                  }}
                >
                  Purchase
                </Button>
              )}
            </div>

            {state.purchase.error && (
              <div className="col-12 form-error mt-3 pl-0">
                There was an error processing your request.
                <br />
                Please try again or contact us at{" "}
                <a className="text-underline" href={`mailto: ${BCX_EMAIL}`}>
                  {BCX_EMAIL}
                </a>{" "}
                or{" "}
                <a className="text-underline" href={`tel:${BCX_PHONE}`}>
                  {BCX_PHONE}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
