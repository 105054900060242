import {
  CreateMembershipPayload,
  CreateMembershipResponse,
} from "Features/MembershipSignUp/Models/createMembership.model";
import API from "../Helpers/API";
import { AxiosResponse } from "axios";
import {
  AddPaymentMethodLinkResponse,
  AddPaymentMethodPayload,
} from "Features/AddPaymentMethod/Models/addPaymentMethod.model";
import { PaymentMethodUpdatePayload } from "../Features/PaymentMethodUpdate/Models/paymentMethodUpdate.model";
import { RequestPaymentMethodUpdateLinkPayload } from "Features/PaymentMethodUpdate/Models/requestPaymentUpdateLink.model";

class PaymentService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createMembership(
    payload: CreateMembershipPayload
  ): Promise<AxiosResponse<CreateMembershipResponse>> {
    return this.api.post({
      location: "/membership",
      body: JSON.stringify(payload),
    });
  }

  public getMembershipDocs(url: string): Promise<any> {
    return this.api.getDownload({
      location: url,
      config: {
        responseType: "blob",
      },
    });
  }

  public getDocumentsStatus(subscriberNumber: string): Promise<any> {
    return this.api.get({
      location: "/membership/documents/status",
      params: { subscriberNumber },
    });
  }

  public requestPaymentMethodUpdateLink(
    payload: RequestPaymentMethodUpdateLinkPayload
  ): Promise<any> {
    return this.api.post({
      location: `/membership/payment-method/request-update-link`,
      body: payload,
    });
  }

  public verifyPaymentMethodLink(link: string): Promise<any> {
    return this.api.get({
      location: `/membership/payment-method/${link}`,
    });
  }

  public updatePaymentMethod(
    link: string,
    payload: PaymentMethodUpdatePayload
  ): Promise<any> {
    return this.api.post({
      location: `/membership/payment-method/${link}`,
      body: payload,
    });
  }

  public verifyAddPaymentLink(
    linkId: string
  ): Promise<AxiosResponse<AddPaymentMethodLinkResponse>> {
    return this.api.get({
      location: `/membership/subscribe/${linkId}`,
    });
  }

  public addPaymentMethod(
    linkId: string,
    payload: AddPaymentMethodPayload
  ): Promise<any> {
    return this.api.post({
      location: `/membership/subscribe/${linkId}`,
      body: payload,
    });
  }
}

export default PaymentService;
