import HeaderService from "Services/HeaderService";
import { HeaderResponse } from "../Models/header.model";
import { RequestState, RxRequest } from "@jauntin/reactables";
import { from, map, of } from "rxjs";

export const RxGetHeaderContent = (headerService: HeaderService) =>
  RxRequest<unknown, HeaderResponse>({
    name: "rxGetHeaderContent",
    sources: [of({ type: "send" })],
    reducers: {
      // Continue to show loading skeleton on failure
      sendFailure: (state): RequestState<HeaderResponse> => state,
    },
    effect: (action$) => {
      return action$.pipe(
        map(() =>
          from(headerService.getHeaderContent()).pipe(map(({ data }) => data))
        )
      );
    },
  });
