import { HeaderResponse } from "Features/Shared/Models/header.model";
import API from "Helpers/API";
import { AxiosResponse } from "axios";

class HeaderService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getHeaderContent(): Promise<AxiosResponse<HeaderResponse>> {
    return this.api.get({
      location: `/wp-json/basicare-plus/v1/header`,
    });
  }
}

export default HeaderService;
