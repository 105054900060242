import { Reactable } from "@reactables/core";
import { RxToggle } from "@jauntin/reactables";
import { from, of, combineLatest, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { RxRequest, RequestState, FormBuilders } from "@jauntin/reactables";
import PaymentService from "Services/PaymentService";
import { ControlModels, group, RxFormActions } from "@reactables/forms";
import { tokenizedToCardInfo } from "Features/Shared/Models/cardInfo.model";
import { Tokenizer } from "Features/Shared/Hooks/useHostedFields";
import {
  PaymentMethodUpdateForm,
  PaymentMethodUpdatePayload,
} from "../Models/paymentMethodUpdate.model";
import {
  PaymentActions,
  paymentReducers,
} from "../../Shared/Rx/Reducers/payment.reducer";
import { payment } from "../../Shared/Rx/Configs/payment.config";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { differentAddressFieldsToBilling } from "../../Shared/Models/billingAddress.model";

export interface PaymentMethodUpdateState {
  submitTouched: boolean;
  verifyLinkRequest: RequestState<unknown>;
  submission: RequestState<unknown>;
  form: ControlModels.Form<PaymentMethodUpdateForm>;
}

interface UpdatePaymentFormActions extends PaymentActions, RxFormActions {}

export type PaymentMethodUpdateActions = {
  form: UpdatePaymentFormActions;
  touchSubmit: () => void;
  submit: (payload: {
    tokenizer: Tokenizer;
    formValue: PaymentMethodUpdateForm;
  }) => void;
};

export const RxPaymentMethodUpdate = ({
  link,
  paymentService,
}: {
  link: string;
  paymentService: PaymentService;
}): Reactable<PaymentMethodUpdateState, PaymentMethodUpdateActions> => {
  const [verifyLinkRequest$] = RxRequest<string, unknown>({
    name: "rxVerifyLinkRequest",
    sources: [of({ type: "send", payload: link })],
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) =>
          from(paymentService.verifyPaymentMethodLink(payload))
        )
      ),
  });

  const [form$, formActions] = FormBuilders.build(
    group({
      controls: {
        payment,
      },
    }),
    {
      name: "rxUpdatePaymentForm",
      reducers: {
        ...paymentReducers,
      },
      providers: formProviders,
    }
  ) as Reactable<
    ControlModels.Form<PaymentMethodUpdateForm>,
    UpdatePaymentFormActions
  >;

  const [submission$, { send: submit }] = RxRequest<
    { tokenizer: Tokenizer; formValue: PaymentMethodUpdateForm },
    unknown
  >({
    name: "rxUpdatePaymentInfoSubmission",
    effect: (action$) =>
      action$.pipe(
        map(({ payload: { tokenizer, formValue } }) =>
          from(tokenizer()).pipe(
            mergeMap((tokenizedPayload) => {
              const payload: PaymentMethodUpdatePayload = {
                cardInfo: tokenizedToCardInfo(tokenizedPayload),
                consentConfirmed: formValue.payment.authorizationConsent,
                billingAddress: (() => {
                  if (formValue.payment.billing.differentAddressFields) {
                    return differentAddressFieldsToBilling(
                      formValue.payment.billing.differentAddressFields
                    );
                  }

                  return undefined;
                })(),
              };

              return from(paymentService.updatePaymentMethod(link, payload));
            })
          )
        )
      ),
  });

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle();

  const state$: Observable<PaymentMethodUpdateState> = combineLatest({
    verifyLinkRequest: verifyLinkRequest$,
    form: form$,
    submission: submission$,
    submitTouched: submitTouched$,
  });

  const actions = {
    form: formActions,
    submit,
    touchSubmit,
  };

  return [state$, actions];
};
