import { Field } from "@reactables/react-forms";
import { Link } from "react-router-dom";
import { ExtendedMeta, scrollToError } from "@jauntin/utilities";
import { EmailInput, PhoneInput } from "@jauntin/react-ui";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import NextButton from "@basicare/frontend/src/Features/Shared/Components/NextButton";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import { useAppStore } from "@reactables/react";

import { genderOptions } from "@basicare/common/src/Constants/genders";
import { AppState } from "Features/Shared/Rx/RxApp";

import { RxMembershipSignUpProp } from "../../Rx/RxMembershipSignUp/RxMembershipSignUp";

import AddressFieldGroup from "@basicare/common/src/Components/FormElements/AddressFieldGroupNew";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Summary from "../Summary/Summary";
import SummaryToggleBtn from "../Summary/SummaryToggleBtn";
import Plans from "./Plans";

import * as FormSelectors from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/form.selector";
import * as GeneralInformationSelectors from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/generalInformation.selector";

const GeneralInformation = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state, actions] = rxMembershipSignUp;

  const [appState] = useAppStore<AppState>();

  if (!state || !appState?.usStates.data?.length) return;

  const { generalInformationSubmitTouched } = state;

  const pageValid =
    GeneralInformationSelectors.isGeneralInformationPageValid(state);

  const showActionButton =
    GeneralInformationSelectors.getShowGeneralInformationActionBtn(state);

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || generalInformationSubmitTouched) && error);

  return (
    <div className="container container--fullwidth-mobile">
      <div className="page-heading">
        <h1>STEP 1: PLEASE ADD PRIMARY MEMBER'S INFORMATION</h1>
      </div>
      <div className="page-wrapper">
        <div className="row row--fullwidth-mobile">
          <div className="col-lg-7 d-flex col--fullwidth-mobile">
            <div className="page-content flex-grow-1">
              <Breadcrumbs />

              <div className="plans">
                <h2>Select your Complete Care Bundle Subscription Interval</h2>
                {FormSelectors.getControl(state, "generalInformation.planType")
                  .errors.required &&
                  generalInformationSubmitTouched && (
                    <div className="form-error mb-1">Required</div>
                  )}
                <Plans rxMembershipSignUp={rxMembershipSignUp} />
              </div>

              <section className="page-content__section personal-information">
                <h2>Personal Information</h2>
                <div className="row">
                  <div className="col-sm-6">
                    <Field
                      name="generalInformation.firstName"
                      component={TextField}
                      label="First Name"
                      placeholder="First Name"
                      labelClassName="label--required"
                      lengthClassName="small"
                      showErrors={showErrors}
                      maxLength={50}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="generalInformation.lastName"
                      component={TextField}
                      label="Last Name"
                      placeholder="Last Name"
                      labelClassName="label--required"
                      lengthClassName="small"
                      showErrors={showErrors}
                      maxLength={50}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="generalInformation.dateOfBirth"
                      component={TextField}
                      inputMode="numeric"
                      label="Date of Birth"
                      placeholder="mm/dd/yyyy"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  <div className="col-sm-6">
                    <Field
                      name="generalInformation.gender"
                      component={SelectList}
                      label="Gender"
                      placeholder="Gender"
                      labelClassName="label--required"
                      options={genderOptions}
                      searchable={false}
                      showErrors={showErrors}
                    />
                  </div>
                </div>
              </section>

              <section className="page-content__section address">
                <h2>Address</h2>
                <AddressFieldGroup
                  googleMapsLoaded={appState?.googleMapsLoaded}
                  groupName="generalInformation"
                  addressValue={
                    FormSelectors.getValue(
                      state,
                      "generalInformation.address"
                    ) as string
                  }
                  onClear={actions.applicationForm.clearPrimaryMemberAddress}
                  usStates={appState.usStates.data}
                  onSelect={actions.applicationForm.selectPrimaryMemberPlace}
                  showErrors={showErrors}
                />
              </section>

              <section className="page-content__section contact">
                <h2>Contact</h2>
                <div className="row">
                  <div className="col-sm-12">
                    <Field
                      name="generalInformation.phoneNumber"
                      component={PhoneInput}
                      label="Phone Number"
                      placeholder="XXX-XXX-XXXX"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  <div className="col-sm-12">
                    <Field
                      name="generalInformation.email"
                      component={EmailInput}
                      label="Email Address"
                      placeholder="your@gmail.com"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  <div className="col-sm-12">
                    <Field
                      name="generalInformation.confirmEmail"
                      component={EmailInput}
                      label="Confirm Email Address"
                      placeholder="your@gmail.com"
                      labelClassName="label--required"
                      showErrors={showErrors}
                    />
                  </div>
                  {GeneralInformationSelectors.getShowEmailNotMatchError(
                    state
                  ) && (
                    <div className="pl-3 form-error">
                      Email Addresses do not match
                    </div>
                  )}
                </div>
              </section>
              {generalInformationSubmitTouched && !pageValid && (
                <div className="form-error mb-2">Check above for errors</div>
              )}

              {showActionButton ? (
                <Link to="/dependents">
                  <NextButton
                    className="w-100 w-lg--auto"
                    onClick={actions.touchGeneralInformationSubmit}
                    disabled={!pageValid}
                  />
                </Link>
              ) : (
                <NextButton
                  className="w-100 w-lg--auto"
                  onClick={() => {
                    actions.touchGeneralInformationSubmit();
                    scrollToError();
                  }}
                />
              )}
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-5">
            <Summary rxMembershipSignUp={rxMembershipSignUp} />
          </div>
          <SummaryToggleBtn rxMembershipSignUp={rxMembershipSignUp} />
        </div>
      </div>
    </div>
  );
};

export default GeneralInformation;
