import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { Reactable, ofTypes, Action } from "@reactables/core";
import {
  Reducers,
  RxToggle,
  RxToggleActions,
  RequestState,
} from "@jauntin/reactables";
import { ControlModels } from "@reactables/forms";
import { RxApplicationForm } from "../RxApplicationForm/RxApplicationForm";
import { RxApplicationFormActions } from "../RxApplicationForm/RxApplicationForm";
import { RxTouchSubmit } from "../RxTouchSubmit";
import { ApplicationForm } from "Features/MembershipSignUp/Models/applicationForm.model";
import { storageEffect } from "./Operators/storageEffect.operator";

import PaymentService from "Services/PaymentService";
import {
  CreateMembershipResponse,
  CreateMembershipResult,
} from "Features/MembershipSignUp/Models/createMembership.model";
import { RxPurchase, RxPurchaseActions } from "../RxPurchase/RxPurchase";
import FacilityService from "Services/FacilityService";
import PromoCodeService from "Services/PromoCodeService";
import {
  RxDownloadDocuments,
  RxDownloadDocumentsActions,
} from "../RxDownloadDocuments/RxDownloadDocuments";
import {
  RxFacilityReferral,
  RxFacilityReferralState,
} from "../RxFacilityReferral/RxFacilityReferral";
import {
  RxProducerReferral,
  RxProducerReferralState,
} from "../RxProducerReferral/RxProducerReferral";
import ProducerService from "Services/ProducerService";
import { HookedReactable } from "@reactables/react";
import { ApplyPromoCodeResponse } from "Features/MembershipSignUp/Models/applyPromoCode.model";
import {
  RxApplyPromoCode,
  ApplyPromoCodeActions,
} from "../RxApplyPromoCode/RxApplyPromoCode";

export interface RxMembershipSignUpActions
  extends RxPurchaseActions,
    RxDownloadDocumentsActions {
  applicationForm: RxApplicationFormActions;
  touchGeneralInformationSubmit: () => void;
  touchPaymentSubmit: () => void;
  summaryDrawerToggle: RxToggleActions;
  applyPromoCode: ApplyPromoCodeActions;
}

export interface RxMembershipSignUpState {
  applyPromoCode: RequestState<ApplyPromoCodeResponse>;
  applicationForm: ControlModels.Form<ApplicationForm>;
  purchase: Reducers.LoadableState<CreateMembershipResponse>;
  documents: Reducers.LoadableState<void>;
  facilityReferral: RxFacilityReferralState;
  producerReferral: RxProducerReferralState;
  generalInformationSubmitTouched: boolean;
  paymentSubmitTouched: boolean;
  summaryDrawerOpen: boolean;
}

export type RxMembershipSignUpProp = HookedReactable<
  RxMembershipSignUpState,
  RxMembershipSignUpActions
>;

/**
 * @description Manages State of Coverage Application including
 * - handling changes from form state (RxApplicationForm)
 * - view state of pages in the application process
 * - persisting state into session storage (to be implemented)
 * - loading state from session storage (to be implemented)
 */
export const RxMembershipSignUp = ({
  promoCodeService,
  paymentService,
  facilityService,
  producerService,
  storage,
  options,
}: {
  promoCodeService: PromoCodeService;
  paymentService: PaymentService;
  facilityService: FacilityService;
  producerService: ProducerService;
  storage: {
    getItem: (name: string) => unknown;
    setItem: (name: string, value: unknown) => void;
    removeItem: (name: string) => void;
  };
  options: {
    referralParams?: string;
    pathOnLoad: string;
    onPurchase: (purchaseResult: CreateMembershipResult) => void;
    onDownloadDocumentsSuccess?: (
      data: string | File | Blob | Uint8Array,
      fileName: string
    ) => void;
    onFacilityReferralError: (error?: unknown) => void;
    onPromoCodeReferralError: () => void;
  };
}): // provide api services here and other options as needed
Reactable<RxMembershipSignUpState, RxMembershipSignUpActions> => {
  /**
   * We will not load from storage and give user a clean form
   * if they refreshed/loaded on home
   */
  if (options.pathOnLoad === "/") {
    storage.removeItem("state");
  }

  const storedState = storage.getItem("state") as RxMembershipSignUpState;

  const [purchase$, purchaseActions, purchaseActions$] = RxPurchase({
    initialState: storedState?.purchase,
    paymentService,
    onPurchase: options.onPurchase,
  });

  const purchaseSuccess$ = purchaseActions$.pipe(ofTypes(["purchaseSuccess"]));

  const [
    generalInformationSubmitTouched$,
    { touchSubmit: touchGeneralInformationSubmit },
  ] = RxTouchSubmit({
    initialState: storedState?.generalInformationSubmitTouched,
    sources: [purchaseSuccess$],
  });

  const [paymentSubmitTouched$, { touchSubmit: touchPaymentSubmit }] =
    RxTouchSubmit({
      initialState: storedState?.paymentSubmitTouched,
      sources: [purchaseSuccess$],
    });

  const [applyPromoCode$, applyPromoCodeActions, applyPromoCodeActions$] =
    RxApplyPromoCode({
      initialState: storedState?.applyPromoCode,
      referralParams: options.referralParams,
      promoCodeService: promoCodeService,
      onPromoCodeReferralError: options.onPromoCodeReferralError,
      sources: [purchaseSuccess$],
    });

  const clearPromoCode$ = applyPromoCodeActions$.pipe(
    ofTypes(["clearPromoCode"])
  );

  const applyPromoCodeSuccess$ = applyPromoCodeActions$.pipe(
    ofTypes(["sendSuccess"]),
    map(({ payload }: Action<ApplyPromoCodeResponse>) => ({
      type: "applyPromoCodeSuccess",
      payload,
    }))
  );

  const [applicationForm$, applicationFormActions] = RxApplicationForm({
    initialState: storedState?.applicationForm,
    sources: [purchaseSuccess$, clearPromoCode$, applyPromoCodeSuccess$],
  });

  const [downloadDocuments$, downloadDocumentsActions] = RxDownloadDocuments({
    initialState: storedState?.documents,
    paymentService,
    onDownloadDocumentsSuccess: options.onDownloadDocumentsSuccess,
  });

  const [facilityReferral$] = RxFacilityReferral({
    facilityService,
    referralParams: options?.referralParams,
    initialState: storedState?.facilityReferral,
    onFacilityReferralError: options.onFacilityReferralError,
  });

  const [producerReferral$] = RxProducerReferral({
    producerService,
    referralParams: options?.referralParams,
    initialState: storedState?.producerReferral,
  });

  const [summaryDrawerOpen$, summaryDrawerToggle] = RxToggle(
    storedState?.summaryDrawerOpen
  );

  const state$ = combineLatest({
    applyPromoCode: applyPromoCode$,
    applicationForm: applicationForm$,
    purchase: purchase$,
    documents: downloadDocuments$,
    facilityReferral: facilityReferral$,
    generalInformationSubmitTouched: generalInformationSubmitTouched$,
    paymentSubmitTouched: paymentSubmitTouched$,
    summaryDrawerOpen: summaryDrawerOpen$,
    producerReferral: producerReferral$,
  }).pipe(storageEffect(storage));

  const actions = {
    ...purchaseActions,
    ...downloadDocumentsActions,
    applicationForm: applicationFormActions,
    touchGeneralInformationSubmit,
    touchPaymentSubmit,
    summaryDrawerToggle,
    applyPromoCode: applyPromoCodeActions,
  } as RxMembershipSignUpActions;

  return [state$, actions];
};
