import { Action, ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { differentAddressFields } from "../Configs/differentAddressFields.config";
import { placesAutocompleteReducers } from "@basicare/common/src/Rx/Reducers/placesAutocomplete.reducer";
import {
  ccFields,
  CCFieldActions,
} from "Features/Shared/Rx/Reducers/ccFields.reducer";

export interface PaymentActions extends ActionMap, CCFieldActions {
  selectSameAddressAsInsured: (payload: boolean) => void;
  selectBillingAddressPlace: (payload: google.maps.places.PlaceResult) => void;
  clearBillingAddress: () => void;
}

const {
  selectPlace: selectBillingAddressPlace,
  clearAddress: clearBillingAddress,
} = placesAutocompleteReducers([
  "payment",
  "billing",
  "differentAddressFields",
  "address",
]);

export const paymentReducers: CustomReducers<PaymentActions> = {
  selectBillingAddressPlace,
  clearBillingAddress,
  selectSameAddressAsInsured: (
    { updateValues, addControl, removeControl },
    state,
    { payload }: Action<boolean>
  ) => {
    const billingRef = ["payment", "billing"];
    state = updateValues(state, {
      controlRef: [...billingRef, "sameAddressAsInsured"],
      value: payload,
    });

    if (payload) {
      state = removeControl(state, [...billingRef, "differentAddressFields"]);
    } else {
      state = addControl(state, {
        controlRef: [...billingRef, "differentAddressFields"],
        config: differentAddressFields,
      });
    }

    return state;
  },
  ...ccFields(["payment", "billing", "ccFieldsValidity"]),
};
