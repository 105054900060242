import { Models } from "@jauntin/reactables";
export interface BillingAddress {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface DifferentAddressFields {
  firstName: string;
  lastName: string;
  address: Models.Address2;
}

export const differentAddressFieldsToBilling = ({
  firstName,
  lastName,
  address: { address: address1, address2, city, state, zip },
}: DifferentAddressFields) => {
  return {
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    country: "US",
  };
};
