import { useReactable } from "@reactables/react";
import { classname } from "@jauntin/utilities";
import { RxToggle } from "@jauntin/reactables";
import { Navbar, NavItem, Nav, NavDropdown, Modal } from "react-bootstrap";
import { RxGetHeaderContent } from "Features/Shared/Rx/RxHeader";
import HeaderService from "Services/HeaderService";
import API from "Helpers/API";
import {
  HeaderOptions,
  Menu,
  NavLogo,
} from "Features/Shared/Models/header.model";
import { useEffect } from "react";
import { useTheme } from "styled-components";
import Button from "@basicare/common/src/Components/Themed/Button";

const ContactInfo = ({ headerOptions }: { headerOptions: HeaderOptions }) => {
  if (headerOptions?.contact_links.length) {
    return (
      <div className="pl-2 mt-4 d-flex flex-column">
        {headerOptions?.contact_links
          .filter(({ show_link }) => show_link)
          .map(({ link: { url, title, target } }, index) => (
            <a key={index} href={url} target={target} title={title}>
              {title}
            </a>
          ))}
      </div>
    );
  }

  return <></>;
};

const GetUrlWithReferralLink = (url: string, referralParam: string): string => {
  if (referralParam) {
    const hashIndex = url.indexOf("#");
    if (hashIndex !== -1) {
      return url.slice(0, hashIndex) + referralParam + url.slice(hashIndex);
    }
  }

  return url + referralParam;
};

const HeaderNav = ({
  menuItems = [],
  referralParam = "",
  mobile = false,
}: {
  menuItems?: Menu[];
  referralParam?: string;
  mobile?: boolean;
}) => (
  <Nav className="header__nav">
    {menuItems.map((item, index) => {
      if (!item.children.length) {
        return (
          <NavItem key={index}>
            <Nav.Link
              className="header__nav-link"
              href={GetUrlWithReferralLink(item.url, referralParam)}
            >
              {item.title}
            </Nav.Link>
          </NavItem>
        );
      }

      return (
        <NavDropdown
          id={`bundles-dropdown-toggle${mobile ? "-mobile" : ""}`}
          title={item.title}
          className="border-0"
          key={index}
        >
          {item.children.map((childItem, childIndex) => (
            <NavDropdown.Item
              className="header__nav-link header__nav-link--dropdown-item"
              href={GetUrlWithReferralLink(childItem.url, referralParam)}
              key={childIndex}
            >
              <NavDropdown.ItemText>{childItem.title}</NavDropdown.ItemText>
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      );
    })}
  </Nav>
);

const NavLogoContainer = ({
  logo,
  referralParam,
  facilityLogoUrl,
  facilityName,
  className,
}: {
  logo: NavLogo;
  referralParam?: string;
  facilityLogoUrl?: string;
  facilityName?: string;
  className?: string;
}) => {
  if (facilityLogoUrl && facilityName) {
    return (
      <a href={`https://basicareplus.com/${referralParam}`}>
        <img
          className={`header__org-logo ml-auto ${className}`}
          src={facilityLogoUrl}
          alt={`${facilityName} Logo`}
        />
      </a>
    );
  }

  return (
    <a href={`https://basicareplus.com/${referralParam}`} className={className}>
      <img
        src={logo?.url}
        width={logo?.width}
        height={logo?.height}
        alt={logo?.alt}
        className="img-fluid header__logo"
      />
    </a>
  );
};

const Header = ({
  referralParam = "",
  facilityLogoUrl,
  facilityName,
}: {
  referralParam?: string;
  facilityLogoUrl?: string;
  facilityName?: string;
}) => {
  const [headerState] = useReactable(
    RxGetHeaderContent,
    new HeaderService(new API(process.env.REACT_APP_WP_API_LOCATION))
  );

  const [showMobileNav, { toggleOn: openMobileNav, toggleOff: hideMobileNav }] =
    useReactable(RxToggle);

  const lgBreakpoint = parseInt(useTheme().breakpoints.lg);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= lgBreakpoint) {
        hideMobileNav();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hideMobileNav, lgBreakpoint]);

  const CtaButton = ({ className = "" }: { className?: string }) => {
    if (!headerState?.data?.header_options?.nav_cta_button) return;

    const {
      show_cta,
      cta: { title, url },
    } = headerState?.data?.header_options?.nav_cta_button;

    return show_cta ? (
      <a className={classname(className, "text-decoration-none")} href={url}>
        <Button
          outline
          className="d-flex justify-content-center align-items-center"
        >
          {title}
          <i className="fa fa-arrow-right ml-2" />
        </Button>
      </a>
    ) : (
      <></>
    );
  };

  return (
    <>
      <header className="header">
        <div className="container-sm">
          <Navbar className="px-0" expand="lg">
            <Navbar.Brand>
              <NavLogoContainer
                logo={headerState?.data?.header_options?.nav_logo}
                referralParam={referralParam}
                facilityLogoUrl={facilityLogoUrl}
                facilityName={facilityName}
                className={
                  headerState?.loading ? "skeleton skeleton-image" : ""
                }
              />
            </Navbar.Brand>
            <Navbar.Toggle
              className="header__hamburger"
              onClick={openMobileNav}
              aria-controls="responsive-navbar-nav"
            ></Navbar.Toggle>
            <div
              className={`d-none d-lg-flex flex-grow-1 ${
                headerState?.loading ? "skeleton skeleton-text" : ""
              } `}
            >
              <HeaderNav
                menuItems={headerState?.data?.menu}
                referralParam={referralParam}
              />
            </div>
            <CtaButton className="d-none d-lg-block ml-auto" />
          </Navbar>
        </div>
      </header>
      <Modal
        dialogClassName="header__dialog"
        show={showMobileNav}
        onHide={hideMobileNav}
      >
        <div className="d-flex align-items-center">
          <NavLogoContainer
            logo={headerState?.data?.header_options?.nav_logo}
            facilityLogoUrl={facilityLogoUrl}
            facilityName={facilityName}
            referralParam={referralParam}
            className={classname(
              headerState?.loading ? "skeleton skeleton-image" : "",
              "mt-3"
            )}
          />
          <button
            type="button"
            className="undecorate-btn d-flex ml-auto p-4 mr-1 font-20"
            onClick={hideMobileNav}
          >
            <i className="fal fa-times c-gray-600" />
          </button>
        </div>
        <div
          className={
            headerState?.loading
              ? "skeleton skeleton-text skeleton-text__small"
              : ""
          }
        >
          <HeaderNav
            menuItems={headerState?.data?.menu}
            referralParam={referralParam}
            mobile
          />
        </div>
        <CtaButton className="mt-3" />
        <ContactInfo headerOptions={headerState?.data?.header_options} />
      </Modal>
    </>
  );
};

export default Header;
