import { Validators } from "@reactables/forms";
import { ValidatorFn } from "@reactables/forms";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import { Organization } from "../Models/organization.model";
import { PromoCodeForm } from "../Models/promoCode.model";
import {
  parse,
  isBefore,
  subYears,
  isSameDay,
  isAfter,
  isValid,
} from "date-fns";
import { validators } from "@jauntin/utilities";
import { plans } from "../Constants/planTypes";
import { DiscountTypes } from "../Constants/discountTypes";
import { PromoCodeTypes } from "../Constants/promoCodeTypes";

const maxAge =
  (age: number): ValidatorFn =>
  (value: string) => {
    const date = parse(value, "MM/dd/yyyy", new Date());
    return {
      [`maxAge${age}`]: isBefore(date, subYears(new Date(), age)),
    };
  };

const maxAge100 = maxAge(100);

export const minLength3: ValidatorFn = (value: string) => ({
  minLength3: Boolean(validators.minLength(3)(value)),
});

export const maxLength20: ValidatorFn = (value: string) => ({
  maxLength10: Boolean(validators.maxLength(20)(value)),
});

export const required = Validators.required;

export const effectiveDate: ValidatorFn = (input: string) => ({
  effectiveDate:
    validDateFormat(input).dateFormat || maxAge100(input).maxAge100,
});

const dateFormat =
  (format: string): ValidatorFn =>
  (value: string) => {
    if (!value) {
      return { dateFormat: false };
    }

    const parsedDate = parse(value, format, new Date());

    return {
      dateFormat:
        String(parsedDate.getFullYear()).length < 4 || !isValid(parsedDate),
    };
  };

export const validDateFormat = dateFormat("MM/dd/yyyy");

export const validDateTimeFormat = dateFormat("yyyy-MM-dd HH:mm");

export const dateOfBirth: ValidatorFn = (input: string) => {
  return {
    dateOfBirth:
      validDateFormat(input).dateFormat ||
      maxAge100(input).maxAge100 ||
      !dateNotInThePast(input).dateNotInThePast,
  };
};

export const dateNotInThePast: ValidatorFn = (input: string) => {
  const date = parse(input, "MM/dd/yyyy", new Date());

  return {
    dateNotInThePast:
      validDateFormat(input).dateFormat ||
      (!isAfter(date, new Date()) && !isSameDay(date, new Date())),
  };
};

export const dateTimeFormat: ValidatorFn = (input: string) => {
  return {
    dateTimeFormat: validDateTimeFormat(input).dateFormat,
  };
};

// XXX-XXX-XXXX
export const phoneNumber: ValidatorFn = (value: string) => ({
  phoneNumber: value && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value),
});

export const emailConfirmed: ValidatorFn = ({
  email,
  confirmEmail,
}: {
  email?: string;
  confirmEmail?: string;
}) => {
  return {
    emailConfirmed: email && confirmEmail && email !== confirmEmail,
  };
};

export const dependentDateOfBirth: ValidatorFn = ({
  dateOfBirth,
  relationshipToAccountHolder,
}: Dependent) => {
  const date = parse(dateOfBirth, "MM/dd/yyyy", new Date());

  return {
    dependentDateOfBirth:
      relationshipToAccountHolder === RelationshipType.Dependent &&
      isBefore(date, subYears(new Date(), 26)),
  };
};

export const oneSpouseMax: ValidatorFn = (
  dependents: {
    confirmed: boolean;
    dependent: Dependent;
  }[]
) => {
  return {
    oneSpouseMax:
      dependents.filter(
        ({ dependent: { relationshipToAccountHolder } }) =>
          relationshipToAccountHolder === RelationshipType.Spouse
      ).length > 1,
  };
};

export const addDependentFormClosed: ValidatorFn = (dependents: {
  addDependentForm;
}) => ({
  addDependentFormClosed: Boolean(dependents.addDependentForm),
});

export const digits9orAlphaNum11: ValidatorFn = (value: string) => {
  return {
    digits9orAlphaNum11: !(
      !Boolean(value?.trim()) ||
      new RegExp(`^([0-9]{${9}})$`, "i").test(value) ||
      new RegExp(`^([0-9A-Za-z]{${11}})$`, "i").test(value)
    ),
  };
};

export const recuroNumRequiredForOrg: ValidatorFn = ({
  organization,
  recuroSubscriberNumber,
}: {
  organization?: Organization;
  recuroSubscriberNumber?: string;
}) => {
  return {
    recuroNumRequiredForOrg:
      organization &&
      organization.requiresRecuroSubscriberNumber &&
      !recuroSubscriberNumber?.trim(),
  };
};

export const max60: ValidatorFn = (value: string) => {
  return { max60: parseInt(value) > 60 };
};

export const promoCodeDiscountDollarAmount: ValidatorFn = ({
  subscriptionInterval,
  discountType,
  discountAmount,
}: PromoCodeForm) => {
  if (!subscriptionInterval) return { promoCodeDiscountDollarAmount: false };

  const { price } = plans.find(
    (plan) => plan.planType === subscriptionInterval
  );

  const hasError =
    discountType === DiscountTypes.Fixed &&
    parseFloat(discountAmount) * 100 > price - 100;

  return {
    promoCodeDiscountDollarAmount: hasError,
  };
};

export const discountAmountRequired: ValidatorFn = ({
  type,
  discountAmount,
}: PromoCodeForm) => ({
  discountAmountRequired:
    type === PromoCodeTypes.Discount && required(discountAmount).required,
});

export const alphaNumericOrDash: ValidatorFn = (value: string) => ({
  alphaNumericOrDash: !/^[a-zA-Z0-9-]*$/.test(value),
});

export const startDateRequired: ValidatorFn = (value: {
  startDate: string;
  endDate?: string;
}) => ({
  startDateRequired: !Boolean(value.startDate),
});

export const promoCodeFormat: ValidatorFn = (value: string) => ({
  promoCodeFormat: !/^(?!-)[A-Z0-9-]{3,20}(?!-)$/.test(value),
});
