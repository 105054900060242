import { Card } from "react-bootstrap";
import { classname } from "@jauntin/utilities";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";
import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { pluralize } from "@basicare/common/src/Helpers/StringHelper";

const PromoCodeDetails = ({
  rxMembershipSignUp: [state, actions],
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  if (!state.applyPromoCode.data) return <></>;

  const { name, type, billingCycles, discountAmount, discountType } =
    state.applyPromoCode.data;

  return (
    <Card
      className={classname(
        "promo-code-details d-flex flex-column p-3",
        `promo-code-details--${state.applicationForm.root.value.generalInformation.planType}`
      )}
    >
      <p>
        Promo Code "{name}" was applied successfully!{" "}
        {type === PromoCodeTypes.Trial ||
        type === PromoCodeTypes.TrialPurchase ? (
          <>
            Your trial period will last for{" "}
            {billingCycles ? billingCycles : "unlimited"} billing{" "}
            {billingCycles ? pluralize("cycle", billingCycles) : "cycles"}.
          </>
        ) : (
          <>
            {discountType === DiscountTypes.Fixed
              ? `${currencyFromInt(discountAmount).formatDollars()} `
              : `${discountAmount}% `}
            discount applied for {billingCycles ? billingCycles : "unlimited"}{" "}
            billing{" "}
            {billingCycles ? pluralize("cycle", billingCycles) : "cycles"}.
          </>
        )}
      </p>
      <button
        className="font-weight-bold btn btn-link ml-auto mt-2"
        onClick={actions.applyPromoCode.clearPromoCode}
      >
        <i className="fal fa-trash mr-2" />
        <span>Remove Promo Code</span>
      </button>
    </Card>
  );
};

export default PromoCodeDetails;
