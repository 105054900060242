import { from, of, combineLatest } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";
import { RxRequest, RequestState, FormBuilders } from "@jauntin/reactables";
import { Reactable } from "@reactables/core";
import {
  ControlModels,
  RxFormActions,
  control,
  group,
} from "@reactables/forms";
import {
  AddPaymentMethodLinkResponse,
  AddPaymentMethodForm,
  AddPaymentMethodPayload,
} from "../Models/addPaymentMethod.model";
import { PaymentActions } from "Features/Shared/Rx/Reducers/payment.reducer";
import { Tokenizer } from "Features/Shared/Hooks/useHostedFields";
import PaymentService from "Services/PaymentService";
import { payment } from "Features/Shared/Rx/Configs/payment.config";
import { paymentReducers } from "Features/Shared/Rx/Reducers/payment.reducer";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { tokenizedToCardInfo } from "Features/Shared/Models/cardInfo.model";
import { differentAddressFieldsToBilling } from "Features/Shared/Models/billingAddress.model";
import { plans } from "@basicare/common/src/Constants/planTypes";

export interface AddPaymentMethodState {
  verifyLinkRequest: RequestState<AddPaymentMethodLinkResponse>;
  form: ControlModels.Form<AddPaymentMethodForm>;
  submission: RequestState<unknown>;
}

interface AddPaymentFormActions extends PaymentActions, RxFormActions {}

export interface AddPaymentMethodActions {
  form: AddPaymentFormActions;
  submit: (payload: {
    tokenizer: Tokenizer;
    formValue: AddPaymentMethodForm;
  }) => void;
}

export const RxAddPaymentMethod = ({
  link,
  paymentService,
  onSubmitSuccess,
}: {
  link: string;
  paymentService: PaymentService;
  onSubmitSuccess?: () => void;
}): Reactable<AddPaymentMethodState, AddPaymentMethodActions> => {
  const [verifyLinkRequest$] = RxRequest<string, AddPaymentMethodLinkResponse>({
    name: "rxAddPaymentLinkRequest",
    sources: [of({ type: "send", payload: link })],
    effect: (actions$) =>
      actions$.pipe(
        map(({ payload }) =>
          from(paymentService.verifyAddPaymentLink(payload)).pipe(
            map(({ data }) => data)
          )
        )
      ),
  });

  const [form$, formActions] = FormBuilders.build(
    group({
      controls: {
        planType: control([null, "required"]),
        payment,
      },
    }),
    {
      name: "rxAddPaymentForm",
      reducers: {
        ...paymentReducers,
      },
      providers: formProviders,
    }
  ) as Reactable<
    ControlModels.Form<AddPaymentMethodForm>,
    AddPaymentFormActions
  >;

  const [submission$, { send: submit }] = RxRequest<
    { tokenizer: Tokenizer; formValue: AddPaymentMethodForm },
    unknown
  >({
    name: "rxAddPaymentSubmission",
    effect: (actions$) =>
      actions$.pipe(
        map(({ payload: { tokenizer, formValue } }) =>
          from(tokenizer()).pipe(
            mergeMap((tokenized) => {
              const cardInfo = {
                ...tokenizedToCardInfo(tokenized),
                amountDetails: {
                  totalAmount: plans.find(
                    (plan) => plan.planType === formValue.planType
                  )?.price,
                  currency: "USD",
                },
              };
              const payload: AddPaymentMethodPayload = {
                subscriptionInterval: formValue.planType,
                cardInfo,
                consentConfirmed: formValue.payment.authorizationConsent,
                billingAddress: (() => {
                  if (formValue.payment.billing.differentAddressFields) {
                    return differentAddressFieldsToBilling(
                      formValue.payment.billing.differentAddressFields
                    );
                  }

                  return undefined;
                })(),
              };

              return from(paymentService.addPaymentMethod(link, payload));
            }),
            tap(() => onSubmitSuccess && onSubmitSuccess())
          )
        )
      ),
  });

  const state$ = combineLatest({
    verifyLinkRequest: verifyLinkRequest$,
    form: form$,
    submission: submission$,
  });

  const actions = {
    form: formActions,
    submit,
  };

  return [state$, actions];
};
