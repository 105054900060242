import { HostedFieldsTokenizePayload } from "braintree-web";

export interface CardInfo {
  token: string;
  cardType: string;
  lastFour: string;
  nameOnCard: string;
  amountDetails?: AmountDetails;
}

interface AmountDetails {
  totalAmount: number;
  currency: string;
}

export const tokenizedToCardInfo = (
  tokenized: HostedFieldsTokenizePayload
): CardInfo => {
  const {
    nonce,
    details: { lastFour, cardType, cardholderName: nameOnCard },
  } = tokenized;

  return {
    token: nonce,
    lastFour,
    cardType,
    nameOnCard,
  };
};
