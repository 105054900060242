import { useHistory } from "react-router-dom";
import Button from "@basicare/common/src/Components/Themed/Button";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";

const ProductCodeNotAllowedError = () => {
  const history = useHistory();

  return (
    <div className="thank-you container container--fullwidth-mobile">
      <div className="page-wrapper">
        <div className="page-content mt-lg-4">
          <div className="thank-you d-flex text-center flex-column align-items-center justify-content-center align-itemscenter">
            <h1 className="text-uppercase mb-4">Oops!</h1>

            <p className="mb-4 text-center">
              This package can't be purchased for this organization. Please
              contact your administrator for more information.
            </p>

            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              Continue without organization
            </Button>

            <p className="mt-4 text-center">
              If you have any questions, please contact us
              <br />
              at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
              <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCodeNotAllowedError;
