import { Reactable } from "@reactables/core";
import { RxToggle } from "@jauntin/reactables";
import { from, combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RxRequest, RequestState, FormBuilders } from "@jauntin/reactables";
import PaymentService from "Services/PaymentService";
import {
  control,
  ControlModels,
  group,
  RxFormActions,
} from "@reactables/forms";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { RequestPaymentMethodUpdateLinkForm } from "../Models/requestPaymentUpdateLink.model";

export interface RequestPaymentMethodUpdateLinkState {
  submitTouched: boolean;
  submission: RequestState<unknown>;
  form: ControlModels.Form<RequestPaymentMethodUpdateLinkForm>;
}

export type RequestPaymentMethodUpdateLinkActions = {
  form: RxFormActions;
  touchSubmit: () => void;
  submit: (payload: { formValue: RequestPaymentMethodUpdateLinkForm }) => void;
};

export const RxRequestPaymentMethodUpdateLink = ({
  paymentService,
}: {
  paymentService: PaymentService;
}): Reactable<
  RequestPaymentMethodUpdateLinkState,
  RequestPaymentMethodUpdateLinkActions
> => {
  const [form$, formActions] = FormBuilders.build(
    group({
      controls: {
        subscriberNumber: control({
          initialValue: "",
          validators: ["required"],
          normalizers: ["alphaNumeric"],
        }),
        phoneNumber: control({
          initialValue: "",
          validators: ["required", "phoneNumber"],
          normalizers: ["normalizePhone"],
        }),
        email: control({
          initialValue: "",
          validators: ["required", "email"],
          normalizers: ["normalizeEmail"],
        }),
        lastFour: control({
          initialValue: "",
          validators: ["required", "fourDigits"],
          normalizers: ["normalize4Digits"],
        }),
      },
    }),
    {
      name: "rxRequestPaymentMethodUpdateLinkForm",
      providers: formProviders,
    }
  ) as Reactable<
    ControlModels.Form<RequestPaymentMethodUpdateLinkForm>,
    RxFormActions
  >;

  const [submission$, { send: submit }] = RxRequest<
    { formValue: RequestPaymentMethodUpdateLinkForm },
    unknown
  >({
    name: "rxRequestPaymentMethodUpdateLinkSubmission",
    effect: (action$) =>
      action$.pipe(
        map(({ payload: { formValue } }) =>
          from(paymentService.requestPaymentMethodUpdateLink(formValue))
        )
      ),
  });

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle();

  const state$: Observable<RequestPaymentMethodUpdateLinkState> = combineLatest(
    {
      form: form$,
      submission: submission$,
      submitTouched: submitTouched$,
    }
  );

  const actions = {
    form: formActions,
    submit,
    touchSubmit,
  };

  return [state$, actions];
};
