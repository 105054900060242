import { normalizers } from "@jauntin/utilities";

const maxInteger = (max: number) => (value: string) => {
  const number = parseInt(value);

  if (isNaN(number)) return "";

  if (number > max) return max;

  return number.toString();
};

export const maxInteger60 = maxInteger(60);

export const normalizeDate = (input: string) => {
  const normalizedInput = input.replace(/\D/g, "");

  if (!normalizedInput) {
    return "";
  }

  const month = normalizedInput.substring(0, 2);
  const day = normalizedInput.substring(2, 4);
  const year = normalizedInput.substring(4, 8);

  if (Number(month[0]) > 1) {
    return `0${month[0]}`;
  }
  if (Number(month) > 12 || Number(month) === 0) {
    return month[0];
  }

  if (day) {
    if (Number(day[0]) > 3) {
      return `${month}/0${day[0]}`;
    }
    if (Number(day) > 31 || Number(day) === 0) {
      return `${month}/${day[0]}`;
    }
  }

  return `${month}${day && `/${day}`}${year && `/${year}`}`;
};

export const normalizeDateTime = (input: string) => {
  const normalizedInput = input.replace(/\D/g, "");

  if (!normalizedInput) {
    return "";
  }

  const year = normalizedInput.substring(0, 4);
  const month = normalizedInput.substring(4, 6);
  const day = normalizedInput.substring(6, 8);
  const hour = normalizedInput.substring(8, 10);
  const minute = normalizedInput.substring(10, 12);

  if (month) {
    if (Number(month[0]) > 1) {
      return `${year}-0${month[0]}`;
    }
    if (Number(month) > 12 || Number(month) === 0) {
      return `${year}-${month[0]}`;
    }
  }

  if (day) {
    if (Number(day[0]) > 3) {
      return `${year}-${month}-0${day[0]}`;
    }
    if (Number(day) > 31 || Number(day) === 0) {
      return `${year}-${month}-${day[0]}`;
    }
  }

  if (hour) {
    if (Number(hour[0]) > 2) {
      return `${year}-${month}-${day} 0${hour[0]}`;
    }
    if (Number(hour) > 59) {
      return `${year}-${month}-${day} ${hour[0]}`;
    }
  }

  if (minute) {
    if (Number(minute[0]) > 5) {
      return `${year}-${month}-${day} ${hour}:0${minute[0]}`;
    }
    if (Number(minute) > 59) {
      return `${year}-${month}-${day} ${hour}:${minute[0]}`;
    }
  }

  return `${year}${month && `-${month}`}${day && `-${day}`}${
    hour && ` ${hour}`
  }${minute && `:${minute}`}`;
};

export const normalizePhone = (value?: string): string => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 3) {
    return onlyNums;
  }

  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(
    6,
    10
  )}`;
};

const maxLength =
  (length: number) =>
  (value?: string): string => {
    if (!value) {
      return value;
    }

    return value.slice(0, length);
  };

export const maxLength5 = maxLength(5);

export const maxLength13 = maxLength(13);

export const maxLength20 = maxLength(20);

export const maxLength50 = maxLength(50);

export const normalize9Digits = normalizers.normalizeDigits(9);

export const alphaNumeric = (value = "") => value.replace(/[^A-Za-z0-9]/g, "");
export const alphaNumericOrDash = (value = "") =>
  value.replace(/[^A-Za-z0-9-]/g, "");

export const normalize11AlphaNums = (value?: string): string => {
  if (!value) {
    return value;
  }
  const onlyAlphaNumeric = alphaNumeric(value);

  return onlyAlphaNumeric.slice(0, 11).toUpperCase();
};

export const uppercase = (value: string) => value.toUpperCase();

export const recuroGroupId = (value: string) =>
  alphaNumeric(uppercase(maxLength20(value)));

export const precentage = (value: string) => {
  const onlyNums = value.replace(/[^\d]/g, "");
  return maxLength(2)(onlyNums);
};

export const formatDiscountDollars = (value: string) => {
  const onlyNums = value.replace(/[^\d]/g, "");

  if (!onlyNums) return "";

  return (parseFloat(maxLength5(onlyNums)) / 100).toFixed(2).toString();
};

export const normalizePrice = (value: string) => {
  const onlyNums = value.replace(/[^\d]/g, "");

  if (!onlyNums) return "";

  return (parseFloat(maxLength5(onlyNums)) / 100).toFixed(2).toString();
};
