import { useAppStore } from "@reactables/react";
import { Field } from "@reactables/react-forms";
import { TextInput } from "@jauntin/react-ui";
import { ExtendedMeta } from "@jauntin/utilities";
import AddressFieldGroup from "@basicare/common/src/Components/FormElements/AddressFieldGroupNew";
import { AppState } from "../Rx/RxApp";

const DifferentAddressFields = ({
  groupName,
  addressValue,
  showErrors = ({ touched, error }) => Boolean(touched && error),
  selectBillingAddressPlace,
  clearBillingAddress,
}: {
  groupName: string;
  addressValue: string;
  selectBillingAddressPlace: (payload: google.maps.places.PlaceResult) => void;
  clearBillingAddress: () => void;
  showErrors?: (meta: ExtendedMeta) => boolean;
}) => {
  const [appState] = useAppStore<AppState>();
  if (!appState) return;

  const {
    googleMapsLoaded,
    usStates: { data: usStates },
  } = appState;
  return (
    <section className="page-content__section">
      <h2 className="mb-3">Billing address information</h2>
      <div className="row">
        <div className="col-12 col-sm-6">
          <Field
            name={`${groupName}.firstName`}
            component={TextInput}
            label="First Name"
            placeholder="First Name"
            labelClassName="label--required"
            lengthClassName="small"
            showErrors={showErrors}
            maxLength={50}
          />
        </div>
        <div className="col-12 col-sm-6">
          <Field
            name={`${groupName}.lastName`}
            component={TextInput}
            label="Last Name"
            labelClassName="label--required"
            placeholder="Last Name"
            lengthClassName="small"
            showErrors={showErrors}
            maxLength={50}
          />
        </div>
      </div>
      {usStates?.length && (
        <AddressFieldGroup
          googleMapsLoaded={googleMapsLoaded}
          groupName={`${groupName}.address`}
          usStates={usStates}
          addressValue={addressValue}
          onClear={clearBillingAddress}
          onSelect={selectBillingAddressPlace}
          showErrors={showErrors}
          fullWidth
        />
      )}
    </section>
  );
};

export default DifferentAddressFields;
