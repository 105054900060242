import { FooterResponse } from "Features/Shared/Models/footer.model";
import API from "Helpers/API";
import { AxiosResponse } from "axios";

class FooterService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getFooterContent(): Promise<AxiosResponse<FooterResponse>> {
    return this.api.get({
      location: `/wp-json/basicare-plus/v1/footer`,
    });
  }
}

export default FooterService;
