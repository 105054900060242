import { Action } from "@reactables/core";
import FacilityService from "Services/FacilityService";
import { EMPTY, from, OperatorFunction, of, throwError } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { FacilityInfoResponse } from "../../../Models/facility.model";
import { MemberErrors } from "@basicare/common/src/Constants/memberErrors";
import { ProductCodes } from "@basicare/common/src/Constants/productCodes";

const FACILITY_CODE_PARAM = "org";

/**
 * @description Provided a query string:
 * Operator looks for a facility code param and looks up Facility info
 */
export const handleFacilityReferral =
  (
    facilityService: FacilityService,
    onFacilityReferralError
  ): OperatorFunction<
    string,
    Action<{
      facilityCode: string;
      facilityName: string;
      facilityLogoUrl: string;
      productCode: string;
    }>
  > =>
  (referralParams$) =>
    referralParams$.pipe(
      filter((referralParams) => Boolean(referralParams)),
      map((referralParams) => {
        const rawParams = referralParams.toLowerCase();
        const urlParams = new URLSearchParams(rawParams);
        return urlParams.get(FACILITY_CODE_PARAM);
      }),
      filter((facilityCode) => {
        return facilityCode && /^([a-z0-9]){3,6}$/i.test(facilityCode);
      }),
      mergeMap((facilityCode) => {
        return from(
          facilityService.getFacilityInfoFromFacilityCode(facilityCode)
        ).pipe(
          map(({ data }: { data: FacilityInfoResponse }) => ({
            facilityCode: data.code,
            facilityName: data.name,
            facilityLogoUrl: data.logoUrl,
            productCode: data.productCode,
          })),
          mergeMap((data) => {
            const { productCode } = data;

            if (
              data.productCode &&
              productCode !== ProductCodes.OnlinePurchaseAllowed
            )
              return throwError(
                () => MemberErrors.OnlinePurchaseProhibitedForProductCode
              );

            return of(data);
          }),
          catchError((error) => {
            onFacilityReferralError && onFacilityReferralError(error);

            return EMPTY;
          })
        );
      }),
      map(({ facilityName, facilityCode, productCode, facilityLogoUrl }) => {
        return {
          type: "facilityReferralSuccess",
          payload: {
            facilityCode,
            facilityName,
            productCode,
            facilityLogoUrl,
          },
        };
      })
    );
