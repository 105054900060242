import FooterService from "Services/FooterService";
import { FooterResponse } from "Features/Shared/Models/footer.model";
import { RequestState, RxRequest } from "@jauntin/reactables";
import { from, map, of } from "rxjs";

export const RxGetFooterContent = (footerService: FooterService) =>
  RxRequest<unknown, FooterResponse>({
    name: "rxGetFooterContent",
    sources: [of({ type: "send" })],
    reducers: {
      // Continue to show loading skeleton on failure
      sendFailure: (state): RequestState<FooterResponse> => state,
    },
    effect: (action$) => {
      return action$.pipe(
        map(() =>
          from(footerService.getFooterContent()).pipe(map(({ data }) => data))
        )
      );
    },
  });
