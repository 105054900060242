import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useReactable } from "@reactables/react";
import { RxPaymentMethodUpdate } from "../Rx/RxPaymentMethodUpdate";
import PaymentService from "Services/PaymentService";
import API from "Helpers/API";
import OopsMessage from "Features/Shared/Components/OopsMessage";
import BrainTreeCCFields, {
  FieldValidStates,
} from "Features/Shared/Components/BrainTreeCCFields";
import { useHostedFields } from "Features/Shared/Hooks/useHostedFields";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import MainLayout from "Features/Shared/Layouts/MainLayout";
import PageContentLayout from "Features/Shared/Layouts/PageContentLayout";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { Form, Field } from "@reactables/react-forms";
import DifferentAddressFields from "../../Shared/Components/DifferentAddressFields";
import { ExtendedMeta } from "@jauntin/utilities";

const PaymentMethodUpdate = () => {
  const { id } = useParams() as { id: string };
  const [state, actions] = useReactable(RxPaymentMethodUpdate, {
    paymentService: new PaymentService(new API()),
    link: id,
  });

  const { bindHostedFields, tokenizer } = useHostedFields();

  if (!state) return;

  const {
    verifyLinkRequest,
    form: { root },
    submission,
  } = state;

  const {
    payment: {
      billing: { differentAddressFields },
    },
  } = root.value;

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || state.submitTouched) && error);

  const termsLink = `${process.env.REACT_APP_WP_API_LOCATION}terms-conditions/`;

  if (verifyLinkRequest.loading)
    return (
      <>
        <div className="d-flex justify-content-center align-items-center h-100vh w-100">
          <Spinner animation="border" role="status" />
          <span className="ml-3">Loading...</span>
        </div>
      </>
    );
  return (
    <MainLayout>
      <PageContentLayout>
        {(() => {
          if (verifyLinkRequest.error) return <OopsMessage />;

          if (submission.success)
            return (
              <div className="confirmation-message mh--300 d-flex flex-column justify-content-center align-items-center">
                <h1 className="confirmation-message__heading font-weight-bold">
                  Thank you for updating your payment information.
                </h1>
                <p className="mt-4 confirmation-message__copy text-center">
                  Questions? Contact us at <br />
                  <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
                  <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
                </p>
              </div>
            );

          const showActionBtn =
            root.valid ||
            state.submitTouched ||
            hasControlTouchError(state.form);

          return (
            <div className="payment-method-update">
              <h1 className="text-center mb-4">
                Please update your payment information below.
              </h1>
              <Form rxForm={[state.form, actions.form]}>
                <BrainTreeCCFields
                  onHostedFieldsLoaded={bindHostedFields}
                  onValidityChange={actions.form.validateCCFields}
                  onBlur={actions.form.markAsCCFieldTouched}
                  fieldValidStates={
                    {
                      number:
                        state.form["payment.billing.ccFieldsValidity.number"],
                      cardholderName:
                        state.form[
                          "payment.billing.ccFieldsValidity.cardholderName"
                        ],
                      expirationDate:
                        state.form[
                          "payment.billing.ccFieldsValidity.expirationDate"
                        ],
                      cvv: state.form["payment.billing.ccFieldsValidity.cvv"],
                    } as FieldValidStates
                  }
                  enableShowErrors={state.submitTouched}
                />
                <Field
                  name="payment.billing.sameAddressAsInsured"
                  component={StyledCheckboxField}
                  onChange={(e) => {
                    actions.form.selectSameAddressAsInsured(
                      e.currentTarget.checked
                    );
                  }}
                  label="Billing address is the same as the primary member"
                />
                {differentAddressFields && (
                  <DifferentAddressFields
                    groupName="payment.billing.differentAddressFields"
                    selectBillingAddressPlace={
                      actions.form.selectBillingAddressPlace
                    }
                    clearBillingAddress={actions.form.clearBillingAddress}
                    addressValue={differentAddressFields.address.address}
                    showErrors={showErrors}
                  />
                )}
                <Field
                  name="payment.authorizationConsent"
                  component={StyledCheckboxField}
                  label={
                    <>
                      By confirming your subscription, you allow BasiCare Plus
                      LLC to charge you now and for future payments in
                      accordance with their{" "}
                      <a href={termsLink} target="_blank" rel="noreferrer">
                        terms
                      </a>
                      .
                    </>
                  }
                  showErrors={showErrors}
                />
              </Form>
              <div className="mt-4 d-flex justify-content-start">
                <TwoStageButton
                  actionEnabled={showActionBtn}
                  action={() =>
                    actions.submit({
                      tokenizer,
                      formValue: state.form.root.value,
                    })
                  }
                  spinning={submission.loading}
                  disableAction={!root.valid}
                  touchSubmit={actions.touchSubmit}
                />
              </div>
              {state.submission.error && (
                <div className="form-error d-flex w-100 justify-content-center mt-3">
                  There was an error, please try again
                </div>
              )}
            </div>
          );
        })()}
      </PageContentLayout>
    </MainLayout>
  );
};

export default PaymentMethodUpdate;
