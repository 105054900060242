import { useRef } from "react";
import { Form } from "@reactables/react-forms";
import { useReactable, useAppStore } from "@reactables/react";
import { RxMembershipSignUp } from "../Rx/RxMembershipSignUp/RxMembershipSignUp";
import { Route, useHistory, useLocation } from "react-router-dom";
import API from "Helpers/API";
import { AppState } from "Features/Shared/Rx/RxApp";
import ScrollToTop from "Features/Shared/Components/ScrollToTop";
import MainLayout from "Features/Shared/Layouts/MainLayout";
import CustomSwitch from "Features/Shared/Components/CustomSwitch";
import Storage from "Helpers/Storage";
import PaymentService from "Services/PaymentService";
import GuardedRoute from "Features/Shared/Components/GuardedRoute";
import GeneralInformation from "./GeneralInformation/GeneralInformation";
import Dependents from "./Dependents/Dependents";
import Payment from "./Payment/Payment";
import ThankYou from "./ThankYou/ThankYou";
import SummaryDrawer from "./Summary/SummaryDrawer";
import GuardRedirect from "./GuardRedirect/GuardRedirect";
import download from "downloadjs";

import { isGeneralInformationPageValid } from "../Rx/RxMembershipSignUp/Selectors/generalInformation.selector";
import { getIsDependentsPageValid } from "../Rx/RxMembershipSignUp/Selectors/dependents.selector";
import { getReferralQueryString } from "../Rx/RxMembershipSignUp/Selectors/referral.selector";
import FacilityService from "Services/FacilityService";
import ProducerService from "Services/ProducerService";
import PromoCodeService from "Services/PromoCodeService";
import { MemberErrors } from "@basicare/common/src/Constants/memberErrors";
import ProductCodeNotAllowedError from "Features/Shared/Components/ProductCodeNotAllowedError";
import InvalidPromoCodeError from "Features/Shared/Components/InvalidPromoCodeError";

declare global {
  interface Window {
    gtag: (...args) => void;
  }
}

const MembershipSignUp = () => {
  const [appState] = useAppStore<AppState>();
  const history = useHistory();
  const location = useLocation();
  const { search } = useRef(location).current;

  const rxMembershipSignUp = useReactable(RxMembershipSignUp, {
    promoCodeService: new PromoCodeService(new API()),
    paymentService: new PaymentService(new API()),
    facilityService: new FacilityService(new API()),
    producerService: new ProducerService(new API()),
    storage: Storage,
    options: {
      referralParams: search,
      pathOnLoad: location.pathname,
      onPurchase: ({ subscriberNumber, total, planType }) => {
        history.push("/thank-you");

        if (window.gtag) {
          window.gtag("event", "purchase", {
            currency: "USD",
            value: total,
            transaction_id: subscriberNumber,
            items: [
              {
                item_id: planType,
                item_name: planType,
              },
            ],
          });
        }
      },
      onDownloadDocumentsSuccess: download,
      onFacilityReferralError: (error) => {
        history.replace({ search: "" });

        if (error === MemberErrors.OnlinePurchaseProhibitedForProductCode) {
          history.push("/oops-no-online-purchase");
        }
      },
      onPromoCodeReferralError: () => {
        history.replace({ search: " " });
        history.push("/oops-invalid-promo-code");
      },
    },
  });

  const [state, actions] = rxMembershipSignUp;

  if (!state || !appState.usStates.data?.length) return;

  const referralParam = getReferralQueryString(state);
  const { facilityLogoUrl, facilityName } = state.facilityReferral;

  return (
    <>
      <MainLayout
        referralParam={referralParam}
        facilityLogoUrl={facilityLogoUrl}
        facilityName={facilityName}
      >
        <main className="main-content">
          <Form rxForm={[state.applicationForm, actions.applicationForm]}>
            <ScrollToTop>
              <CustomSwitch>
                <Route path="/thank-you">
                  <ThankYou rxMembershipSignUp={rxMembershipSignUp} />
                </Route>
                <Route
                  component={ProductCodeNotAllowedError}
                  path="/oops-no-online-purchase"
                />
                <Route
                  component={InvalidPromoCodeError}
                  path="/oops-invalid-promo-code"
                />
                <GuardedRoute
                  component={Dependents}
                  rxMembershipSignUp={rxMembershipSignUp}
                  path="/dependents"
                  state={state}
                  guards={[isGeneralInformationPageValid]}
                  redirectComponent={() => (
                    <GuardRedirect rxMembershipSignUp={rxMembershipSignUp} />
                  )}
                />
                <GuardedRoute
                  component={Payment}
                  rxMembershipSignUp={rxMembershipSignUp}
                  path="/payment"
                  state={state}
                  guards={[
                    isGeneralInformationPageValid,
                    getIsDependentsPageValid,
                  ]}
                  redirectComponent={() => (
                    <GuardRedirect rxMembershipSignUp={rxMembershipSignUp} />
                  )}
                />
                <Route path="/">
                  <GeneralInformation rxMembershipSignUp={rxMembershipSignUp} />
                </Route>
              </CustomSwitch>
            </ScrollToTop>
            <SummaryDrawer
              rxMembershipSignUp={rxMembershipSignUp}
              show={state.summaryDrawerOpen}
              close={actions.summaryDrawerToggle.toggleOff}
            />
          </Form>
        </main>
      </MainLayout>
    </>
  );
};

export default MembershipSignUp;
